import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable({
    providedIn: 'root'
})
export class NavigateproService {
    private statusIdSubject = new BehaviorSubject<number>(1); // Set default value here
    statusId$ = this.statusIdSubject.asObservable();
    statusId: any;

    constructor() { }

    setStatusId(statusId: number) {
        this.statusId = statusId;
        this.statusIdSubject.next(statusId);
    }

    // Method to retrieve the stored statusId
    getStatusId() {
        return this.statusId;
    }
}
