import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router'
@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
    router: any;

  constructor(private viewportScroller: ViewportScroller) {}

  public onClick(elementId: string): void { 
      this.viewportScroller.scrollToAnchor(elementId);
  }
    Schools() {
        this.router.navigate(['/Schools']);
    } home() {
        this.router.navigate(['/']);
    } about() {
        this.router.navigate(['/About']);
    } contact() {
        this.router.navigate(['/Contact']);
    }
    Address() {
        this.router.navigate(['/Address']);
    } WorkWear() {
        this.router.navigate(['/WorkWears']);
    } Orders() {
        this.router.navigate(['/Orders']);
    }
  ngOnInit() {
  }

}
