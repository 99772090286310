import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { Router } from '@angular/router'
import { HttpClient } from '@angular/common/http';
import { from } from 'rxjs';
@Component({
    selector: 'app-home-one',
    templateUrl: './home-one.component.html',
    styleUrls: ['./home-one.component.scss']
})
export class HomeOneComponent implements OnInit {

    constructor(private viewportScroller: ViewportScroller, public router: Router, public http: HttpClient) {
        debugger
        var PaymentStatus = localStorage.getItem("PaymentStatus");
        if (PaymentStatus != "True") {
            this.Gettransactionstatus();
        } else if (PaymentStatus == "True") {
            localStorage.removeItem("PaymentStatus");
            localStorage.removeItem("TransId");
        }
    }

    public onClick(elementId: string): void { 
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() {
    }

    Gettransactionstatus() {
        debugger
        var TransId = localStorage.getItem("TransId");
        if (TransId != null) {
            //const apiUrl = "https://localhost:44326/api/DressBox/CheckPaymentStatus?merchantTransactionId=" + TransId;
            const apiUrl = "https://dressbox.in/webservices/api/DressBox/CheckPaymentStatus?merchantTransactionId=" + TransId; // Replace with your actual API URL
            this.http.get(apiUrl).subscribe((response: any) => {
                debugger
                var result = response.data.state
                //this.responsestatus = result;
                if (result == "COMPLETED" || result == "COMPLETED ") {
                    localStorage.setItem("PaymentStatus", "True");
                    this.router.navigate(['/CheckOut', { PaymentValue: 1 }]);
                    localStorage.removeItem("TransId");
                  //  this.PlaceOrder();
                }
            })
        }
    }


    Packages() {
        this.router.navigate(['/Packages']);
    }
}
