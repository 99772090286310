<div id="services" class="services-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <h2>Our <span>DressBox</span> Services</h2>
            <p>At DressBox, we offer a comprehensive range of services tailored to meet the uniform needs of various sectors</p>
        </div>
        <div class="row justify-content-center">

            <!--<div class="col-lg-3 col-md-6"routerLink="/Schools">
        <div class="single-services">
            <div class="services-img">
                <img src="../../../../assets/img/Dressbox/school.jpg"alt="services-img" />

            </div>
            <div class="services-content">
                <h3>Schools</h3>
                <p>
                    We specialize in providing high-quality school uniforms that reflect your institution's ethos and values. Our extensive range of
                    uniforms includes options for students of all ages, ensuring comfort, durability, and style.
                </p>
            </div>
        </div>
    </div>-->
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <!--  <img src="../../../../assets/img/Dressbox/uniforms.jpg" alt="services-img" />-->
                        <img src="../../../../assets/img/newimages/3.jpeg" alt="services-img" />
                        <!--<div class="icon">
        <i class="fab fa-linode"></i>
    </div>-->
                    </div>
                    <div class="services-content">
                        <h3>School Uniforms</h3>
                        <p>Whether you're looking for school uniforms or corporate attire, DressBox has you covered. Our uniforms are crafted with precision and attention to detail, ensuring a professional and polished look for every wearer.</p>
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="../../../../assets/img/newimages/5.jpeg" alt="services-img" />
                        <!--<div class="icon">
                    <i class="fas fa-anchor"></i>
                </div>-->
                    </div>
                    <div class="services-content">
                        <h3>School Packages</h3>
                        <p>Our school packages are designed to simplify the uniform procurement process for schools and parents alike. With customizable packages tailored to your school's requirements</p><br />
                    </div>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
                <div class="single-services">
                    <div class="services-img">
                        <img src="../../../../assets/img/Dressbox/workwear.jpg" alt="services-img" />
                        <!--<div class="icon">
                    <i class="fas fa-desktop"></i>
                </div>-->
                    </div>
                    <div class="services-content">
                        <h3>Work Wear</h3>
                        <p>DressBox offers professional workwear solutions for a wide range of industries, including hospitals, industries, and hotels. Our workwear is designed to meet industry standards while providing comfort and durability</p>
                    </div>
                </div>
            </div>



        </div>
    </div>
</div>
