import { Component, OnInit } from '@angular/core';
import { GeneralserviceService } from '../../../Services/generalservice.service';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Route, Router } from '@angular/router';
import Swal from 'sweetalert2';
declare var $
import { ViewportScroller } from '@angular/common';
import { NavigateproService } from '../../../Services/navigatepro.service'
@Component({
  selector: 'app-welcome',
  templateUrl: './welcome.component.html',
  styleUrls: ['./welcome.component.scss']
})
export class WelcomeComponent implements OnInit {
    loginDet: any;
    schooldata: any;
    HomeUrl: any;
    arr: any = [];
    initialRecordsToShow: number = 10;
    showAllRecords: boolean = false;
    constructor(public navigateservice: NavigateproService,public generalService: GeneralserviceService, public router: Router, public fb: FormBuilder, public http: HttpClient) {
        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });

    }
    ngOnInit(): void {

        this.getschool();

    }
    toggleRecords() {
        this.showAllRecords = !this.showAllRecords;
    }


    navigateproduct(data) {
        debugger

        this.router.navigate(['/SchoolProducts/' + data]);
    }
    readmore() {
        this.router.navigate(['/Schools']);
    }
    getschool() {
        debugger
        this.arr = []
        this.arr.push({
            RegID: '2',
            TokenID: 'D864AE02-4029-4014-9B11-7D8609DD1622',
        })
        var UploadFile = new FormData()
        UploadFile.append("Param", JSON.stringify(this.arr));
        UploadFile.append("Flag", '4');
        var url = "api/DressBox/School_Master";
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
            this.schooldata = data;
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }
}
