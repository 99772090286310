
<div id="about" class="about-area ptb-100">
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <ul>
                <li style="cursor:pointer;"><a routerLink="/home-one">Home / </a></li>
                <li><a routerLink="/Schools" style="text-transform:capitalize">Schools / {{SchoolName}}/</a></li>
                <li (click)="navigatingtoCategory(this.Productdata[0].SchoolName)">Products/{{TypeName}}</li>
                <div>Product Details</div>


            </ul>
        </div>
    </nav>

    <div class="container">
        <div class="row">
            <div class="col-lg-4">
                <div class="card1 card_border single ">
                    <div class="hover01 column">
                        <div *ngIf="Image != null ||Image != '' ">
                            <figure> <img src="{{HomeUrl+Image}}" class="card_image" alt="DB" *ngIf="Image"></figure>
                        </div>
                        <div *ngIf="Image == null || Image == '' ">
                            <figure>  <img src="../../../assets/img/NutrigeneLogo.png" class="card_image" alt="DB"></figure>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-8">

                <div class="row">
                    <div class="col-lg-7">
                        <h4 class="product_title">{{ProductName}}</h4>
                        <hr />
                        <div class="col-lg-12 col-xs-12 col-12 "> &nbsp; <b style="color:#fa8224">₹{{ Price  }}</b> (<small>This price is inclusive of all Taxes</small>)</div>
                        <hr />
                        <div class="mobile_pro ">
                            <div class="col-lg-12 col-xs-12 col-12  ">
                                <p class="product_details" style="color:black">Product Details</p>
                            </div>
                            <div class="row">
                                <div class="col-lg-6"><div class="bold">School/Industry&nbsp;</div></div>
                                <div class="col-lg-6"><div>{{SchoolName}}</div></div>
                                <div class="col-lg-6"><div class="bold">Type Name</div></div>
                                <div class="col-lg-6"><div> {{TypeName}}</div></div>
                                <div class="col-lg-6"><div class="bold">Price</div></div>
                                <div class="col-lg-6"><div>₹{{ Price }}</div></div>
                                <!--<div class="col-lg-6"><div class="bold">Gender</div></div>
            <div class="col-lg-6"><div>{{GenderName}}</div></div>-->

                            </div>

                        </div>
                        <div>
                            <p class="product_details" style="color:black">Available&nbsp;Options</p>
                            <div class="row">
                                <div class="col-lg-6 col-6">
                                    <label for="typeId" style="font-weight:bold">Select Size:</label>
                                    <br />
                                    <select id="typeId" style="cursor:pointer" [(ngModel)]="Name" required>
                                        <option *ngFor="let type of sizedata" [value]="type.Name">{{ type.Name }} </option>
                                    </select>
                                </div>
                                <div class="col-lg-4 col-6">
                                    <label for="quantity" style="font-weight:bold">Quantity</label><br />
                                    <select name="quantity" id="quantity" [(ngModel)]="quantity" (click)="updatePrice()" required style="cursor:pointer">
                                        <option value="" disabled selected hidden>Choose an option</option>
                                        <option *ngFor="let i of [1,2,3,4,5,6,7,8,9,10]" [value]="i">{{i}}</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                        <button (click)="Addtocart(Productdata[0])"><i class="fas fa-shopping-cart" style="color:white;margin:0"></i> Add to cart</button>

                    </div>
                   
                </div>
            </div>
        </div>
    </div><br /><hr />
    <div class="container-fluid  mt-3">
        <div class="container">
            <div class="row">
                <div class="col-lg-12 ">
                    <h5 class="discription" style="color:black;text-decoration:none">Product Details</h5>
                    <p class="text-align_j" [innerHtml]="ProductInfo">
                    </p>
                </div>
            </div>

        </div>
    </div><br />
    <hr />
    <div class="container-fluid bg-white  mt-3">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <h5 class="discription" style="color:black;text-decoration:none">Recommended Product </h5>
                </div>
                <div class="row">
                    <!--<app-relatedproducts [(ProductId)]="ProductId" (someEvent)="navigatingToPopularProd($event)"></app-relatedproducts>-->
                    <!--<div class="col-lg-3 col-md-2 col-sm-2 col-xs-2" *ngFor="let item of RProductdata">
                        <div class="card card_border single">
                        <div class="card-bodyy hover01 column" (click)="navigateproduct(item.Productid,item.ProductName)">
                            <div class="demoWrapper">
                                <div *ngIf="item.Image != null ||item.Image != '' ">
                                    <figure>   <img src="{{HomeUrl+item.Image}}" class="card_image" alt="DB" *ngIf="item.Image"></figure>
                                </div>
                                <div *ngIf=" item.Image == null || item.Image == '' ">
                                    <figure>  <img src="../../../assets/img/NutrigeneLogo.png" class="card_image" alt="DB"></figure>
                                </div>
                            </div>
                            <hr class="hrl" />
                            <h5 class="card-titlle a_g99">{{item.ProductName}}</h5>
                            <div style="color:black"><b> {{item.Price | currency :'INR'}}</b></div>
                            <br />
                        </div>
                    </div>
                    </div>-->
                    <div class="col-lg-3 col-md-2 col-sm-2 col-xs-2" *ngFor="let item of RProductdata">
                        <div (click)="navigateproduct(item.Productid,item.ProductName)">
                            <div *ngIf="item.Image != null ||item.Image != '' ">
                                <figure>   <img src="{{HomeUrl+item.Image}}" class="relimage" alt="DB" *ngIf="item.Image"></figure>
                            </div>
                            <div *ngIf=" item.Image == null || item.Image == '' ">
                                <figure>  <img src="../../../assets/img/NutrigeneLogo.png" class="relimage" alt="DB"></figure>
                            </div>
                            <p class="relproduct" (click)="navigateproduct(item.Productid,item.ProductName)">{{item.ProductName}}</p>
                            <div style="color:black" class="relprice">  {{item.Price | currency :'INR'}} </div>
                            <!--<p class="readmore">>>read more</p>-->
                        </div>


                    </div>

                </div>
            </div>
        </div>
    </div>






</div>
<style>
    @media only screen and (max-width:600px){
        .col-xs-6 {
            padding: 1px;
            width: 50% !important;
        }
    }
</style>
