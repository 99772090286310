<div id="about" class="about-area ptb-100">
    <div class="container">
        <h3 style="text-align:center">Profile Details</h3><br />
        <div class="col-lg-12">
            <div class="row">
                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-lg-3">
                            <label>Full Name</label>
                        </div>
                        <div class="col-lg-3">
                            <input class="prof" [(ngModel)]="Fullname" disabled />
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-lg-3">
                            <label> Mobile</label>
                        </div>
                        <div class="col-lg-3">
                            <input class="prof" [(ngModel)]="Mobile" disabled />
                        </div>
                    </div>
                </div> <br /><br />
                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-lg-3">
                            <label>Email</label>
                        </div>
                        <div class="col-lg-3">
                            <input class="prof" [(ngModel)]="Email" disabled />
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-lg-3">
                            <label>Admission No</label>
                        </div>
                        <div class="col-lg-3">
                            <input class="prof" [(ngModel)]="AdmissionNo" disabled />
                        </div>
                    </div>
                </div><br /><br />

                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-lg-3">
                            <label>Class Name</label>
                        </div>
                        <div class="col-lg-3">
                            <input class="prof" [(ngModel)]="ClassName" disabled />
                        </div>
                    </div>
                </div>

                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-lg-3">
                            <label>School Name</label>
                        </div>
                        <div class="col-lg-3">
                            <input class="prof" [(ngModel)]="schoolname" disabled />
                        </div>
                    </div>
                </div><br /><br />

                <div class="col-lg-6">
                    <div class="row">
                        <div class="col-lg-3">
                            <label>Syllabus Type</label>
                        </div>
                        <div class="col-lg-3">
                            <input class="prof" [(ngModel)]="SyllabusType" disabled />
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>

</div>


