<div id="about" class="about-area ptb-100">
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <ul>
                <li style="cursor:pointer;"><a routerLink="/Schools"><b style="color:white;">Home / </b></a></li>
                <li class="breadcrumb-item " aria-current="page"><b style="color:white;">My Orders</b></li>
                <div>My Orders</div>
            </ul>
        </div>
    </nav>


    <div class="container">
        <div class="row">
            <div class="col-lg-4 col-md-2 col-sm-2 col-xs-2 col-xs-12" *ngFor="let item1 of OrderedProductList; let i=index">
                <div class="card" (click)="navigateToInvoice(item1)" [ngClass]="{'latest-order': i === 0}"style="padding:10px">
                    <div class="row">
                        <div class="col-lg-6  col-xs-6"style="padding:0">
                            <div class="cardItems" style="color: #fd6a4e;padding:10px">{{item1.OrderID}}</div>
                        </div>
                        <div class="col-lg-6  col-xs-6"style="padding:0">
                            <div class="cardItems"style="padding:10px">{{item1.CreatedDate | date:'dd-MMM-yyyy hh:mm a'}}</div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-lg-6 col-xs-6"style="padding:0">
                                <ng-container *ngIf="isPending(item1.StatusName)">
                                    <div class="cardItems"style="padding:10px">Thank For Your Payment Order is in Process</div>
                                </ng-container>
                                <ng-container *ngIf="!isPending(item1.StatusName)">
                                    <div class="cardItems"style="padding:10px">{{item1.StatusName}}</div>
                                </ng-container>
                            <!--<p>Thank For Your Payment Order is in Process</p>
    <p class="cardItems">{{item1.StatusName}}</p>-->
                        </div>
                        <div class="col-lg-6 col-xs-6"style="padding:0">
                            <div class="cardItems1"style="padding:10px">Order Amount: ₹ {{item1.TotalPrice}}</div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
