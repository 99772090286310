<div id="about" class="about-area ptb-100">
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <ul>
                <li style="cursor:pointer;"><a routerLink="/home-one"><b style="color:white;">Home / </b></a></li>
                <li class="breadcrumb-item " aria-current="page"><b style="color:white;">Add to cart</b></li>
                <div>Add to cart</div>
            </ul>
        </div>
    </nav>
    <div class="container">
        <div class="container">
            <div class="row">
                <div class="col-lg-12">
                    <div class="table-responsive">
                        <table class="table">
                            <thead>
                                <tr>
                                    <th scope="col">Product</th>
                                    <th scope="col">Size</th>
                                    <th scope="col">Price&nbsp;(₹)</th>
                                    <th scope="col">Quantity</th>
                                    <th scope="col">Subtotal&nbsp;(₹)</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let items of cartItems; let i = index">
                                    <th scope="row">
                                        <div class="row">
                                            <div class="col-lg-3 col-xs-12">
                                                <img src="{{HomeURL + items.Image}}" alt="..." style="width: 50px;" />
                                            </div>
                                            <div class="col-lg-9 col-xs-12">   {{items.ProductName}}</div>
                                        </div>
                                    </th>
                                    <td>{{items.Size}}</td>
                                    <td>{{items.Price | number: '1.2-2'}}</td>
                                    <td>
                                        <select [(ngModel)]="items.Quantity" (change)="changeQuantity()">
                                            <option value="" disabled selected hidden>Choose an option</option>
                                            <option *ngFor="let i of [1, 2, 3, 4, 5, 6, 7, 8, 9, 10]" [value]="i">{{i}}</option>
                                        </select>
                                    </td>
                                    <td>
                                        {{calculateSubTotal(items) | number: '1.2-2'}}
                                        <a (click)="deleteCartfromTable(i, items.CartId)"><i class="fa fa-trash"></i></a>
                                    </td>
                                </tr>
                                <tr>
                                    <th scope="row"></th>
                                    <td></td>
                                    <td></td>
                                    <td><strong>GrandTotal</strong></td>
                                    <td><strong>{{Grandtotal | number: '1.2-2'}}</strong></td>
                                </tr>
                                <tr>
                                    <th scope="row">
                                        <a (click)="navigatae()" class="btn" style="background-color: #f38100; color: white;">
                                            <i class="fa fa-angle-left"></i>Continue Shopping
                                        </a>
                                    </th>
                                    <td></td>
                                    <td></td>
                                    <td></td>
                                    <td>
                                        <a class="btn btn-success btn-block checkout" style="padding: 5px; background: #2b7689;" (click)="PlaceOrder()">
                                            Checkout <i class="fa fa-angle-right"></i>
                                        </a>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>

                    <style>
                        @media (max-width: 767px) {
                            .table-responsive {
                                overflow-x: auto;
                            }
                        }
                    </style>

                </div>

                
                <!--<div class="col-lg-2">
                    <h4>Address</h4>
                    <div class="card"style="padding:5px;margin-top:3%" *ngFor="let item of  addressDetails let i=index">
                        <span>
                            <input type="radio" name="addressRadio" [(ngModel)]="addressId" value="{{item.AID}}" (change)="getSelectedAddress(item.AID)" style="width: 18px;height: 18px;" /> My Address {{i+1}}
                            &nbsp;{{item.Area}}, {{item.HNo}}, {{item.Landmark}},<br />
                            {{item.City}},{{item.State}},{{item.Address}}
                        </span>
                    </div>
                </div>-->
            </div>
         
        </div>
    </div>
</div>
<div class="modal" id="myModal">
    <div class="modal-dialog" role="document">
        <div class="modal-content">
            <div class="modal-header state modal-primary">
                <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">Select Delivery mode</h4>



            </div>
            <div class="modal-body">
                <!--<input type="radio" name="mode" (change)="getPaymentType('Online')" /><span>&nbsp;&nbsp;&nbsp;Online payment</span>-->
                <input type="radio" name="mode" (change)="getPaymentType('cashondelivery')" /><span>&nbsp;&nbsp;&nbsp;Cash on delivery</span><br /><br />
                <br />
                <br />
                <div class="modal-footer">

                    <button id="closemodal" type="button" class="btn btn-success" (click)="PlaceOrder()" style="margin-right:46%;">Ok</button>
                    <button id="closemodal" type="button" class="btn btn-danger" (click)="cancel()" style="margin-left:-43%;">Cancel</button>
                </div>

            </div>
        </div>
    </div>
</div>
