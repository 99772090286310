<div id="about" class="about-area ptb-100">
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <ul>
                <li style="cursor:pointer;"><a routerLink="/home-one"><b style="color:white;">Home / </b></a></li>
                <li style="cursor:pointer;"><a><b style="color:white;">Checkout </b></a></li>
                <div>Checkout</div>
            </ul>
        </div>
    </nav>
    <div class="container">
        
            <div class="row">            
                <div class="col-lg-12">
                          <div class="row">
                              <div class="col-lg-7"><strong>Delivery Details</strong><h6>{{loginDetails[0].Name}}/{{loginDetails[0].Mobile}}</h6></div>
                              <div class="col-lg-5">
                                
                              </div>
                              <div class="col-lg-4" *ngFor="let item of  addressDetails let i=index">
                                <div class="card" >
                                    <span>
                                        <input type="radio" name="addressRadio" [(ngModel)]="addressId" value="{{item.AID}}" (change)="getSelectedAddress(item.AID)" style="width: 18px;height: 18px;" /> My Address {{i+1}}
                                        &nbsp;{{loginDetails[0].schoolname}},{{loginDetails[0].ClassName}},{{loginDetails[0].SyllabusType}},{{item.HNo}}, {{item.Landmark}},<br />
                                        {{item.City}},{{item.State}},{{item.Address}}
                                    </span>
                                </div>
                            </div>
                          </div>
                </div>

                <div class="col-lg-3">
                    <div class="row mt-4">
                        <div class="col-lg-6 col-6"> <div>Total Payable:</div> </div>
                        <div class="col-lg-6 col-6"><h6>{{packagesarr[0].TotalPrice | currency:'INR'}} </h6></div>

                    </div>
                </div>

                </div>
                <div class="row">
                    <div class="col-lg-4 col-xs-6">
                        <button class="btn btn-success btn-block" style="    padding: 5px;background: #2b7689;" (click)="phonepePayment()">
                            CONTINUE <i class="fa fa-angle-right"></i>
                        </button>
                    </div>
                    <div class="col-lg-4 col-xs-6">
                        <div>
                            <button class="addnew" (click)="addAddress()">Add&nbsp;New&nbsp;Address</button>
                        </div>
                    </div>
                    <div class="col-lg-4">
                    </div>
                </div>
             
    </div>
</div>

