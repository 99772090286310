
<div id="about" class="about-area ptb-100">
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <ul>
                <li style="cursor:pointer;"><a routerLink="/home-one"><b style="color:white;">Home / </b></a></li>
                <li class="breadcrumb-item " aria-current="page"><b style="color:white;" routerLink="/Schools">Schools</b></li>
                <div>Products</div>
            </ul>
        </div>
    </nav>

    <div class="container" style="margin-top:-4%;">
        <div class="row" style="background-color:#2b768908;border-radius:5px;padding:5px">
            <div class="col-lg-6 col-xs-6">
                <div style="padding:10px;color:black;font-weight:bold">{{Sname}}</div>
            </div>
                <div class="col-lg-2 col-xs-4 col-xs-6">
                <a (click)="package()" class="btn btnpackage view-work" >Packages</a>

            </div>
            <div class="col-lg-2 col-xs-1 col-xs-6">
            <button class=" btn-danger btn-sm fresh" (click)="refresh()" style="width: 50px; margin-top: 0px;"><i class="fa fa-refresh"></i></button>          </div>
           

            <!--<div class="col-lg-1 col-xs-3 col-2" (click)="selectGender(2)" [ngClass]="{ 'selected': selectedGender === 2 }">
                <div class="icon_user">
                    <i class="fas fa-female" style="cursor:pointer"></i>
                    <div class="round1" style="cursor:pointer">Girl</div>
                </div>
            </div>
            <div class="col-lg-1 col-xs-3 col-2" (click)="selectGender(1)" [ngClass]="{ 'selected': selectedGender === 1 }">
                <div class="icon_user">
                    <i class="fas fa-child" style="cursor:pointer"></i>
                    <div class="round1" style="cursor:pointer">Boy</div>
                </div>
            </div>-->

        </div>
        <div class="row">
            <div class="col-lg-12 ">
                
                    <div class="row">
                       <!-- <div class="col-lg-8 col-xs-6"> <h5>Filter By</h5></div>-->
                        <div class="col-lg-4 col-xs-6">
                            <!--i class="fas fa-sync-alt"></i>-->
                        </div>
                    </div>
       

                    <hr />

                    <!--<div>

                        <div>
                            <ul>

                                <li *ngFor="let item of typedata" style="height: 44px; font-size: smaller;">

                                    <input type="radio" name="listType" value="ordered" (change)="selectListType(item.TypeID)" />
                                    {{ item.TypeName }} 
                                </li>
                            </ul>
                        </div>

                    </div>-->
                </div>
            <div class="col-lg-12">
                <div class="row">
                    <!--<div class="hover01 column">
                        <div>
                            <figure><img src="https://picsum.photos/300/200?image=244" /></figure>
                            <span>Hover</span>
                        </div>
                        </div>-->
                        <div class="col-lg-3 col-md-2 col-sm-2 col-xs-2 col-xs-6" *ngFor="let item of schooldata">
                            <div class="card card_border single hover01 column">
                                <div class="card-bodyy text-center  hover01 column" (click)="navigateproduct(item.Productid,item.ProductName)">
                                    <div class="demoWrapper ">
                                        <div *ngIf="item.Image != null ||item.Image != '' ">
                                            <figure>  <img src="{{HomeUrl+item.Image}}" class="card_image" alt="DB" *ngIf="item.Image"></figure>
                                        </div>
                                        <div *ngIf=" item.Image == null || item.Image == '' ">
                                            <figure> <img src="../../../assets/img/NutrigeneLogo.png" class="card_image" alt="DB"></figure>
                                        </div>
                                    </div>
                                    
                                    <hr class="hrl" />
                                    <div class="row">
                                        <div class="col-lg-6 col-xs-6">
                                            <h5 class="card-titlle" style="float:left;margin-left:15%">{{item.ProductName}}</h5>
                                        </div>  <br />
                                        <div class="col-lg-4 col-xs-6">
                                            <h5 class="card-titlle itemprice">₹{{item.Price}}.00</h5>
                                        </div>  <br />
                                        <div class="col-lg-2"></div>
                                    </div>
                                    <br />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
        </div>
    </div>
    </div>
