
<div id="about" class="about-area ptb-100">
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <ul>
                <li style="cursor:pointer;"><a routerLink="/home-one"><b style="color:white;">Home / </b></a></li>
                <li (click)="navigatingtoCategory(this.Productdata[0].SchoolName)"><b style="color:white;">Packages / </b></li>
                <li aria-current="page"><b style="color:white;">Package details</b></li>
                <div> Package details</div>
            </ul>
        </div>
    </nav>


    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <div class="card1">
                    <div class="column">
                        <div *ngIf="Image != null ||Image != '' ">
                            <figure> <img src="{{HomeUrl+Image}}" class="card_image" alt="DB" *ngIf="Image"></figure>
                        </div>
                        <div *ngIf="Image == null || Image == '' ">
                            <figure>  <img src="../../../assets/img/NutrigeneLogo.png" class="card_image" alt="DB"></figure>
                        </div>
                    </div>
                </div>
            </div>
            <div class="col-lg-5">
                <div class="row">
                    <div class="col-lg-12"><h5 class="product_title">{{PakageName}}</h5></div>
                    <hr />
                    <div class="col-lg-12 col-xs-6 col-12 "><p><b>PRICE:</b>  &nbsp;{{TPrice | currency:'INR'}} <small>This price is inclusive of all Taxes</small></p></div>
                    <div class="col-lg-12 col-xs-6 col-12 "></div>
                    <hr />

                    <div class="row">
                        <div class="col-lg-8 col-12">
                            <label for="quantity"><b>Quantity: &nbsp;</b></label>
                            <select name="quantity" style="width: 20%;" id="quantity" [(ngModel)]="quantity" (change)="updatePrice()" required>
                                <option value="" disabled selected hidden>Choose an option</option>
                                <option *ngFor="let qty of quantities" [value]="qty">{{ qty }}</option>
                            </select>
                        </div>
                    </div>

                    <div class="col-lg-12 col-xs-6 col-6 mt-2 mb-2  ">
                        <b>Package Details</b>
                    </div>
                    <div class="row">
                        <div class="col-lg-12">
                            <table class="table">
                                <thead>
                                    <tr>
                                        <th>Image</th>
                                        <th>Product Name</th>
                                        <th>Size</th>

                                    </tr>
                                </thead>
                                <tbody>
                                    <ng-container *ngFor="let item of itemsdata">
                                        <tr>
                                            <td><img *ngIf="item.Image" [src]="HomeUrl + item.Image" alt="Product Image" style="width: 45px; height: 45px;padding:6px"></td>
                                            <td>{{ item.ProductName }}</td>
                                            <td>
                                                <select *ngIf="sizedata[item.TypeID]" (change)="selectListType($event.target.value, item)">
                                                    <option value="" disabled selected hidden>Select Size</option>
                                                    <option *ngFor="let size of sizedata[item.TypeID]" [value]="size.Name">{{ size.Name }}</option>
                                                </select>
                                            </td>
                                            <!--<td>
                                            <button class="btn btn-primary" (click)="addtocart(item)" [disabled]="!item.selectedSize">Add to Cart</button>
                                        </td>-->

                                        </tr>
                                    </ng-container>
                                </tbody>
                            </table>
                        </div>
                    </div>




                </div>


                <!--<div class="row">
                <div class="col-lg-12">
                    <button (click)="Addtocart(1)">
                        <i class="fas fa-shopping-cart" style="color:white"></i> Add to cart
                    </button>
                </div>



            </div>-->
                <div>
                    <a class="btn btn-success btn-block checkout" style="padding: 5px; background: #2b7689;" (click)="PlaceOrder()">
                        Checkout <i class="fa fa-angle-right"></i>
                    </a>


                </div>

                <!--<div class="col-lg-2">
                <h4>Address</h4>
                <div class="card" style="padding:5px;margin-top:3%" *ngFor="let item of  addressDetails let i=index">
                    <span>
                        <input type="radio" name="addressRadio" [(ngModel)]="addressId" value="{{item.AID}}" (change)="getSelectedAddress(item.AID)" style="width: 18px;height: 18px;" /> My Address {{i+1}}
                        &nbsp;{{item.Area}}, {{item.HNo}}, {{item.Landmark}},<br />
                        {{item.City}},{{item.State}},{{item.Address}}
                    </span>
                </div>
            </div>-->
                <!--<div class="col-lg-2">

                <div class="card" style="padding:5px;margin-top:3%;width:160%">
                    <div class="col-lg-12 col-xs-6 col-6 xspadding ">
                        <p class="product_details text-cente">Price Details in RS</p>
                    </div><hr class="hr1" />

                    <div class="row">
                        <div class="col-lg-8  product_data1"><div>Subtotal </div></div>
                        <div class="col-lg-4 text-end">{{Price}}</div>
                        <hr class="hr1" />
                        <div class="col-lg-8  product_data1"><div>Delivery Charges</div></div>
                        <div class="col-lg-4 text-end">Free</div>
                        <hr class="hr1" /> <div class="col-lg-6  mt-1 product_data"> <div>Total Payable</div> </div>
                        <div class="col-lg-6 text-end mt-1"><h6>{{Price | currency:'INR'}}</h6></div>

                    </div>


                </div>

            </div>-->




            </div>


        </div>
        <div class="row">

            <div class="col-lg-12">
                <p class="discription ">Package Details</p>
            </div>
            <div class="col-lg-12">
                <p class="text-align_j" [innerHtml]="Description">
                </p>
            </div>
        </div>

       



        <!--<div class="col-lg-12">
        <h4>Recommended Product </h4><br />
    </div>
    <div class="row">
        <div class="col-lg-3 col-md-2 col-sm-2 col-xs-2" *ngFor="let item of RProductdata">
            <div class="card card_border single">
                <div class="card-bodyy hover01 column" (click)="navigateproduct(item.Productid)">
                    <div class="demoWrapper">
                        <div *ngIf="item.Image != null ||item.Image != '' ">
                            <figure>   <img src="{{HomeUrl+item.Image}}" class="card_image" alt="DB" *ngIf="item.Image"></figure>
                        </div>
                        <div *ngIf=" item.Image == null || item.Image == '' ">
                            <figure>  <img src="../../../assets/img/NutrigeneLogo.png" class="card_image" alt="DB"></figure>
                        </div>
                    </div>
                    <hr class="hrl" />
                    <h5 class="card-titlle a_g99">{{item.ProductName}}</h5>
                    <div style="color:black"><b> ₹ {{item.Price}}.00</b></div>
                    <br />
                </div>
            </div>
        </div>
    </div>-->
    </div>
    
         
            <div class="container-fluid bg-white  mt-3">
                <div class="container">
                    <div class="row">
                        <div class="col-lg-12">
                            <p class="discription ">Recommended Product </p><hr />
                        </div>
                        <div class="row">
                            <div class="col-lg-3 col-md-2 col-sm-2 col-xs-2 card" *ngFor="let item of RProductdata">
                                <div (click)="navigateproduct(item.Productid)">
                                    <div *ngIf="item.Image != null ||item.Image != '' ">
                                        <figure>   <img src="{{HomeUrl+item.Image}}" class="relimage" alt="DB" *ngIf="item.Image"></figure>
                                    </div>
                                    <div *ngIf=" item.Image == null || item.Image == '' ">
                                        <figure>  <img src="../../../assets/img/NutrigeneLogo.png" class="relimage" alt="DB"></figure>
                                    </div>
                                    <p class="relproduct"(click)="navigateproduct(item.Productid)">{{item.ProductName}}</p>
                                    <div style="color:black" class="relprice">  {{item.Price | currency :'INR'}} </div>
                                    <p class="readmore">>>read more</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
       
  

</div>
