<div class="container">
    <br />
    <div class="row">
        <div class="col-lg-3">
            <div class="mt-5 pt-5">
                <button type="submit" class="btn btn-danger hidden-print" routerLink="/MyOrders">Back</button>
            </div>
        </div>
        <div class="col-lg-8"> </div>

    </div>
    <div class="row">
        <div id="print-section">
            <table class="font_1">

                <tr>
                    <td colspan="4" style="    border-right: none;"></td>
                    <td colspan="1" style="    border-left: none;border-right: none;">
                        <div class="mobilelogo">
                            <img src="../../../../assets/img/newimages/logo.jpeg" class="table_img " alt="Logo" />
                        </div>
                    </td>
                    <td colspan="3" style="   border-left: none; border-right: none;"></td>
                    <td colspan="4" style="    border-left: none;"></td>

                </tr>

                <tr>
                    <td colspan="4" style="text-align:center;border-right:none"></td>
                    <td colspan="5" style="text-align:center;border:none" class="invoice-title"><b class="tax_invoice">Tax Invoice</b></td>
                    <td colspan="3" style="border-left:none"></td>
                </tr>


                <tr>
                    <td colspan="5" class="bill-to" style="text-align:left;">
                        <b class="bill-to-label">Bill From:</b><br />
                        <span>Dress Box</span><br />
                        <span>+91 9876543210</span><br />
                        <span>Info@dressbox.com</span><br />
                        <span>Vanasthalipuram, Hyderabad 500070</span>
                    </td>

                    <td colspan="5" class="bill-to" style="text-align:left;">
                        <b class="bill-to-label">Bill To:</b><br />
                        <span class="bill-to-details">
                            {{orderDetails[0].Name}} - {{orderDetails[0].Mobile}}<br />
                            {{orderDetails[0].HNo}}, {{orderDetails[0].Landmark}},<br />
                            {{orderDetails[0].Address}},<br />
                            {{orderDetails[0].City}}, {{orderDetails[0].State}} - {{orderDetails[0].Pincode}}<br />
                        </span>
                    </td>
                </tr>

                <tr>
                    <td colspan="5">
                        <table>
                            <tr>
                                <th style="text-align:left">Order Id :</th>
                                <td style="text-align:right">{{orderNo}}</td>
                            </tr>
                            <tr>
                                <th style="text-align:left">Order Date :</th>
                                <td style="text-align:right">{{orderDate | date:'dd-MMM-yyyy hh:mm a'}}</td>
                            </tr>
                            <tr>
                                <th style="text-align:left">Transaction Status :</th>
                                <td style="text-align:right">{{TransactionStatus}}</td>
                            </tr>
                        </table>
                    </td>
                    <td colspan="5">
                        <table>
                            <tr>
                                <th style="text-align:left">Invoice No:</th>
                                <td><span>IN{{billNo}}</span></td>
                            </tr>
                            <tr>
                                <th style="text-align:left">Invoice Date:</th>
                                <td><span>{{orderDate | date:'dd-MMM-yyyy hh:mm a'}}</span></td>
                            </tr>
                            <tr>
                                <th style="text-align:left">Transaction No:</th>
                                <td><span>{{billNo}}</span></td>
                            </tr>
                        </table>
                    </td>
                </tr>

                <tr>
                    <td colspan="10" class="item-list">
                        <table>
                            <thead>
                                <tr>
                                    <th class="item-list-header">S No</th>
                                    <th class="item-list-header">School Name/ Work wear</th>
                                    <th class="item-list-header">Product/ Package</th>
                                    <th class="item-list-header" style="text-align: center;">Quantity</th>
                                    <th class="item-list-header" style="text-align: right;">Size</th>
                                    <th class="item-list-header" style="text-align: right;">Price</th>
                                    <th class="item-list-header" style="text-align: right;">Total Price</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr *ngFor="let item1 of orderDetails; let i = index" class="item-row">
                                    <td class="item-list-cell">{{i + 1}}</td>
                                    <td class="item-list-cell" *ngIf="item1.ProductName !== ''">{{item1.SchoolName}}</td>
                                    <td class="item-list-cell" *ngIf="item1.ProductName === ''">{{item1.PackageSchoolName}}</td>
                                    <td class="item-list-cell" *ngIf="item1.ProductName !== ''">{{item1.ProductName}}</td>
                                    <td class="item-list-cell" *ngIf="item1.ProductName === ''">{{item1.PakageName}}</td>
                                    <td class="item-list-cell">{{item1.Quantity}}</td>
                                    <td class="item-list-cell">{{item1.Size }}</td>
                                    <td style="width:4%; text-align:right">{{item1.Price}}.00</td>
                                    <td style="width:10%;">{{item1.TotalPrice }}.00</td>

                                </tr>
                        </table>
                    </td>
                </tr>
                <tr>
                    <td colspan="12">
                        <table>
                            <tr>
                                <td colspan="2" style="text-align:left" *ngIf="packagedetails && packagedetails.length > 0"><b>ProductName</b></td>
                                <td colspan="1" style="text-align:right" *ngIf="packagedetails && packagedetails.length > 0"><b>Size</b></td>
                                <td style="border:none"></td>
                                <td style="border:none"></td>
                            </tr>
                            <tr *ngFor="let item of packagedetails">

                                <td colspan="2" style="text-align:left">{{item.ProductName}}</td>
                                <td colspan="1" style="text-align:right">{{item.Size}}</td>
                                <td style="border:none"></td>
                                <td style="border:none"></td>
                            </tr>


                        </table>
                    </td>
                </tr>
                <tr style="border:none;text-align:right">
                    <td colspan="9" style="border-right:none"></td>
                    <td colspan="3" style="border-left:none">
                        <div><b> Total Amount: Rs.{{TotalPrice}}.00</b></div>
                    </td>                
                </tr>
                <tr>
                    <td colspan="12" style="text-align:center">
                        No Exchanges and Refunds.<br />
                        This is computer generated invoice
                    </td>
                </tr>
            </table>


            <p style="text-align:right">
                <br />
                <button type="submit" class="btn btn-primary hidden-print" (click)="print()">Print</button>
                &nbsp;
                <button type="submit" class="btn btn-danger hidden-print" routerLink="/MyOrders">Cancel</button>
            </p>

            <script src="script.js"></script>
        </div>

    </div>
</div>
<!--this code use for google analyticals-->









<style>


    .sticky-container1 {
        padding: 0;
        margin: 500px 0 0;
        position: fixed;
        right: -169px;
        top: 230px;
        width: 210px;
        z-index: 1100;
    }

    .sticky1 {
        position: fixed;
        top: 0;
        width: 100%;
        z-index: 999;
        margin-top: 20rem;
    }

        .sticky1 li {
            border: 1px solid rgba(18, 54, 84, 0.43);
            list-style-type: none;
            background-color: #fff;
            color: #efefef;
            height: 43px;
            padding: 0;
            margin: 0 0 8px;
            transition: all .25s ease-in-out;
            cursor: pointer;
        }

            .sticky1 li img {
                float: left;
                margin: 5px 5px 5px 4px;
            }

            .sticky1 li p {
                padding-top: 5px;
                margin: 0;
                line-height: 16px;
                font-size: 11px;
            }

                .sticky1 li p a {
                    text-decoration: none;
                    color: #2c3539;
                }

            .sticky1 li p {
                padding-top: 5px;
                margin: 0;
                line-height: 16px;
                font-size: 11px;
            }

            .sticky1 li:hover {
                margin-left: -115px;
            }

    .modal-dialog {
        max-width: 60% !important;
        margin: 1.75rem auto !important;
    }

    .card {
        box-shadow: 0 3px 5px rgba(0, 0, 0, 0.11), 0 3px 6px rgba(0, 0, 0, 0.02);
    }

    .custom.tabs-primary {
        background-color: rgba(62, 80, 180, 0) !important;
        border: 1px solid #ffffff !important;
        COLOR: #000 !important;
    }

    .nav-item .nav-link {
        color: rgba(0, 0, 0, 0.8) !important;
    }

        .nav-item .nav-link.active {
            background-color: #ffffff !important;
            border-bottom: 3px solid #9ed9f7 !important;
        }

        .nav-item .nav-link:hover {
            background-color: #9ed9f7 !important;
            border-bottom: 3px solid #9ed9f7 !important;
        }

    .HideDescription {
        width: 100%;
        overflow: hidden;
        display: inline-block;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .strg_c {
        font-family: poppins;
        font-size: 12px;
        color: #000;
        letter-spacing: 0.5px;
        line-height: 1.8;
        text-align: center;
        padding-top: 0;
    }

    .strg {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        color: #000;
        letter-spacing: 0.5px;
        text-align: center;
        padding-top: 0.5rem
    }

    .ordstrn {
        font-family: Arial;
        font-size: 15px;
        color: #000;
        font-weight: normal;
        letter-spacing: 0.5px;
    }

    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }

    td, th {
        border: 1px solid #dddddd;
        text-align: right;
        padding: 8px;
    }

    #plugin {
        height: 30% !important;
        position: absolute;
        width: 100%;
    }
</style>

