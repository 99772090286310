import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';
import { Subject, BehaviorSubject } from 'rxjs';


function _window(): any {
    return _window;
}

@Injectable({
    providedIn: 'root'
})

export class CartserviceService {
    ShowAlert(arg0: string, arg1: string, arg2: string) {
        throw new Error('Method not implemented.');
    }
    PostData(url: string, uploadfile: FormData) {
        throw new Error('Method not implemented.');
    }

   

    public cartItemList: any = []
    public ordeDetails: any = []
    public relatedProductDetails: any = []
    public prodctList = new BehaviorSubject<any>([]);
    public orderList = new BehaviorSubject<any>([]);
    public relatedProdList = new BehaviorSubject<any>([]);

    constructor() {
        const storedItems = localStorage.getItem('cartItems');
        if (storedItems) {
            this.cartItemList = JSON.parse(storedItems);
            this.prodctList.next(this.cartItemList);
        }}

    getProducts() {
        return this.prodctList.asObservable();
    }
    setProducts(product: any) {
        this.cartItemList.push(...product);
        this.prodctList.next(product)
    }
    addtoCart(products: any) {
        debugger
        this.cartItemList.push(products)
        //this.prodctList.next(this.cartItemList);
        this.updateLocalStorage();
       
    }
    private updateLocalStorage() {
        localStorage.setItem('cartItems', JSON.stringify(this.cartItemList));
        this.prodctList.next(this.cartItemList);
    }
    getTotalPrice() {
        let grandTotal = 0;
        this.cartItemList.map((a: any) => {
            grandTotal += a.MRp * 1;
        })
        return grandTotal;
    }

    removeCartItems(index: any) {
        this.cartItemList.splice(index, 1)
        this.prodctList.next(this.cartItemList);
    }

    removeAllcart() {
        debugger
        this.cartItemList = [];
        this.prodctList.next(this.cartItemList);
    }

    showOrderdetails(orders: any) {
        this.ordeDetails = []
        this.ordeDetails.push(orders)
        this.orderList.next(this.ordeDetails)
    }
    getOrderNumber() {
        return this.orderList.asObservable();
    }

    setRelatedproductId(productId: any) {
        debugger
        this.relatedProductDetails = []
        this.relatedProductDetails.push(this.cartItemList[0].Productid)
        this.relatedProdList.next(this.relatedProductDetails)
    }
    getrelatedProdNumber() {
        return this.relatedProdList.asObservable();
    }
}
