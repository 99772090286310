<div id="about" class="about-area ptb-100">
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <ul>
                <li style="cursor:pointer;"><a routerLink="/home-one"><b style="color:white;">Home / </b></a></li>
                <li class="breadcrumb-item " aria-current="page"><b style="color:white;">About Us</b></li>
                <div>About Us</div>
            </ul>
        </div>
    </nav>
    <div class="container">
        <div class="section-title">
            <h2>About <span>Dressbox</span></h2>
            <p>We take pride in using branded fabrics and ensuring superior stitching quality for all our garments.</p>
        </div>
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="about-content">
                    <div class="section-title">
                        <h2>OUR <span>History</span></h2>
                        <p>
                           <b> Established in 2001, Dressbox is a renowned manufacturer of uniforms with over 24 years of experience in the industry. Our flagship
                            store is located in Vanasthalipuram,</b> where we offer a diverse range of products tailored to meet the needs of schools,
                            sports teams, corporations, hospitals, industries, and hotels.
                        </p>
                    </div>
                    <!--<ul class="features-list">
                        <li><i class="fas fa-check"></i>Creative Design</li>
                        <li><i class="fas fa-check"></i>Retina Ready</li>
                        <li><i class="fas fa-check"></i>Responsive Design</li>
                        <li><i class="fas fa-check"></i>Modern Design</li>
                        <li><i class="fas fa-check"></i>Awesome Design</li>
                        <li><i class="fas fa-check"></i>Digital Marketing & Branding</li>
                    </ul>-->
                    <p>At Dressbox, we take pride in our commitment to quality and customer satisfaction. <b> We specialize in providing high-quality uniforms made
                    from branded fabrics and ensuring superior stitching quality.</b>  In addition to uniforms, we also offer a wide range of accessories including
                    ties, belts, shoes, bags, lunchboxes, water bottles, caps, and fancy uniforms.</p>
                </div>
            </div>
            <div class="col-lg-6 col-md-12">
                <div class="about-video">
                    <!--<img src="../../../../assets/img/Dressbox/about.png" alt="about" />-->
                    <img src="../../../../assets/img/newimages/6.jpeg" alt="about" />

                    <div class="video-btn">
                        <!-- <button class="popup-youtube" (click)="ngxSmartModalService.getModal('popupOne').open()"><i class="fas fa-play"></i></button> -->
                    </div>
                </div>
            </div>
            <p>Our pricing is competitive, and we strive to offer the best value to our customers without compromising on quality. We believe in building long-term relationships with our clients by delivering exceptional products and services.</p>
            <p>Our team is dedicated to providing personalized assistance to ensure that every customer finds the perfect solution for their uniform needs. Whether you're looking for school uniforms, sports uniforms, or corporate wear, we've got you covered.</p>
        </div>
    </div>
</div>

<div class="video-popup">
    <!-- <ngx-smart-modal #popupOne [identifier]="'popupOne'">
        <iframe src="https://www.youtube.com/embed/WQ119jAN4Xo" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
    </ngx-smart-modal> -->
</div>
