
<div id="welcome" class="welcome-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            
            <h2>Welcome to <span>DressBox</span></h2>
          
        </div>
       
        <div class="row">
            <p>
                Established in 2001,Dressbox is a leading manufacturer of uniforms, offering a wide range of high-quality products to meet your needs.
                From school uniforms to sports uniforms, corporate wear to workwear for hospitals, industries, and hotels, we have you covered.
            </p>
            <div class="col-lg-2 col-md-2 col-sm-2 col-xs-2 col-xs-6"  *ngFor="let item of schooldata | slice:0:12; let i = index">
                <br />
                <div class="card card_border single" >
                    <div class="card-bodyy text-center"  (click)="navigateproduct(item.Name)">
                        <!-- Added "text-center" class -->
                        <div class="demoWrapper">
                            <div *ngIf="item.Image != null || item.Image != '' ">
                                <img src="{{ HomeUrl + item.Image }}" class="card_image" alt="DB" *ngIf="item.Image">
                            </div>
                            <div *ngIf="item.Image == null || item.Image == '' ">
                                <img src="../../../assets/img/NutrigeneLogo.png" class="card_image" alt="DB">
                            </div>
                        </div>
                        <hr class="hrl" />
                        <h5 class="card-titlle ">{{ item.Name }}</h5>
                        <br />
                    </div>
                </div>
            </div>

            <div class="col-lg-9"></div>
            <div class="col-lg-3">
                <button (click)="readmore()">Read More</button>
            </div>


        </div>
    </div>
    <div class="shape2"><img src="assets/img/shape1.png" alt="image"></div>
</div>
