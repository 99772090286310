<div id="about" class="about-area ptb-100">
    <br /><br /><br />

    <div class="card">

        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header state modal-primary">
                    <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">Enter Mobile</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="Close()"><span aria-hidden="true"></span></button>
                </div>
                <div class="modal-body">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <label>Mobile<span style="color:red">*</span></label>
                        <input [(ngModel)]="Mobile" class="form-control Caps" placeholder="Enter Mobile" type="text" maxlength="10" oninput="this.value = this.value.replace(/[^0-9]/g, '').slice(0, 10)" style="width:450px">
                    </div>


                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <label>Password<span style="color:red">*</span></label>
                        <input [(ngModel)]="Password" class="form-control Caps" placeholder="Enter Password" type="text" maxlength="10" oninput="this.value = this.value.replace(/[^0-9]/g, '').slice(0, 10)" style="width:450px">
                    </div>
                    <div class="modal-footer">
                        <button type="submit" class="btn btn-default" (click)="CheckMobile(Mobile)">Submit</button>
                        <button id="closemodal" type="button" class="btn btn-default" data-dismiss="modal" (click)="Close()">Close</button>
                    </div>
                </div>
        </div>

    </div><br /><br />

                <div class="card" *ngIf="mobileotp">
                    <div class="modal" id="myModal3" >
                        <div class="modal-dialog" role="document">
                            <div class="modal-content">
                                <div class="modal-header state modal-primary">
                                    <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">Enter Otp</h4>
                                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="Close()"><span aria-hidden="true">Ã—</span></button>
                                </div>
                                <div class="modal-body">
                                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                                        <label>OTP<span style="color:red">*</span></label>
                                        <input [(ngModel)]="OTP" class="form-control Caps" placeholder="Enter Otp" type="text" style="width:450px">
                                    </div>
                                    <div class="row" style="float:right;cursor:pointer;margin-right:10px">
                                        <a class="resend" (click)="SendOtpForMobile()">Resend OTP</a>
                                    </div>
                                </div>
                                <div class="modal-footer">
                                    <button type="submit" class="btn btn-default" (click)="VerifyOtp(OTP)">Submit</button>
                                    <button id="closemodal" type="button" class="btn btn-default" data-dismiss="modal" (click)="Close()">Close</button>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
</div>
