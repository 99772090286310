import { Component } from '@angular/core';
import { GeneralserviceService } from '../../Services/generalservice.service';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute} from '@angular/router';
import Swal from 'sweetalert2';
import { NavigateproService } from '../../Services/navigatepro.service'
@Component({
  selector: 'app-packages',
  templateUrl: './packages.component.html',
  styleUrls: ['./packages.component.scss']
})
export class PackagesComponent {
    loginDet: any;
    schooldata: any;
    HomeUrl: any;
    arr: any = [];
    arr1: any = [];
    schoolpro: any;
    packagedata: any;
    ww: any;
    selectedGender: any | null = null;
    Sname: any;
    constructor(public activeroute: ActivatedRoute,public generalService: GeneralserviceService, public fb: FormBuilder, public navigateservice: NavigateproService, public http: HttpClient, public router: Router) {
        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));

        this.Sname = this.activeroute.snapshot.paramMap.get('Packagename').replace(/\_/g, " ").trim();

        this.generalService.getProducts().subscribe(res => {
            if (res.length == 0) {//if category id is null
                this.getschoolpackages();
            }
            else {

                this.Sname = res[0].Name;
                this.getschoolpackages();

            }
        });
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });

    
    }
    ngOnInit(): void {

        

    }

    getschoolpackages() {
        debugger
       
        var UploadFile = new FormData()
        UploadFile.append("Param", this.loginDet[0].ClassId);
        UploadFile.append("Gender", this.loginDet[0].Gender);
       
        var url = "api/DressBox/GetPackages_basedon_PackageName";
        this.generalService.PostData(url, UploadFile).then(data => {
            debugger
          this.packagedata=data
            this
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Some thing went Wrong', 'error')

        })
    }

    selectGender(gender: any) {
        debugger
        this.selectedGender = gender;
        console.log('Selected Gender:', this.selectedGender);

        this.Filterproducts()
        // You can perform any additional actions here based on the selected gender
    }
    refresh() {
        this.getschoolpackages();
    }
    Filterproducts() {
        debugger

        var UploadFile = new FormData()
        UploadFile.append("Param", this.selectedGender !== undefined || this.selectedGender !== null ? this.selectedGender : '');
        UploadFile.append("Param1", this.Sname);
        var url = "api/DressBox/Get_FilterPackage_Based_on_FilterItems";
        this.generalService.PostData(url, UploadFile).then((data: any) => {
            debugger
            if (data.length === 0) {
                this.generalService.ShowAlert('No Packages', 'No Packages ', 'error');
            } else {
                this.packagedata = data;
            }
        }, err => {
            this.generalService.ShowAlert('ERROR', 'Please wait A mint', 'error');
        });
    }


    navigateproduct(data,name) {
        debugger

       
        this.router.navigate(['/PackageProduct/' + data, name]);
    }
    
}
