<footer class="footer-area">
    <div class="container">
        <div class="row">
            <div class="col-lg-3">
                <a routerLink="/">  <img src="../../../../assets/img/newimages/logo.jpeg" alt="Dressbox" class="dresslogo1" /></a>
                <p>Dressbox is a renowned manufacturer of uniforms with over 24 years of experience in the industry. </p>

            </div>

            <div class="col-lg-3">
                <h4>Quick Links</h4><br />
                <div><a routerLink="/">Home</a></div>
                <div><a routerLink="/About">About</a></div>
                <div><a routerLink="/Schools">Schools</a></div>
                <div><a routerLink="/WorkWears">Work Wear</a></div>
                <div><a routerLink="/Contact">Contact Us</a></div>

            </div>
            <div class="col-lg-3">
                <h4>Quick Links</h4><br />
                <div><a target="_blank" href="PrivacyPolicy.html">Privacy Policy </a></div>
                <div><a target="_blank" href="TermsAndConditions.html">Terms & Conditions </a></div>
                <div><a target="_blank" href="Return&Refund.html">Return & Refund Policy </a></div>
                <div><a target="_blank" href="Shipping.html">Shipping Policy </a></div>

            </div>
            <div class="col-lg-3">
                <h4>Contact Links</h4><br />
                <div> Vanasthalipuram, Hyderabad 500070 </div>
                <div>+91 7799912406</div>
                <div>Info@dressbox.com</div>
                <ul>
                    <li><a href="https://www.facebook.com/login/" target="_blank" class="fab fa-facebook-f"></a></li>
                    <li><a href="http://www.discardless.eu/twitter.com/login62b1.html?lang=en-gb" target="_blank" class="fab fa-twitter"></a></li>
                    <li><a href="https://www.linkedin.com/login" target="_blank" class="fab fa-linkedin-in"></a></li>
                    <li><a href="https://www.instagram.com/" target="_blank" class="fab fa-instagram"></a></li>
                    <li><a href="https://signup.live.com/signup?lcid=1033&wa=wsignin1.0&rpsnv=152&ct=1717054069&rver=7.5.2156.0&wp=MBI_SSL&wreply=https%3a%2f%2flw.skype.com%2flogin%2foauth%2fproxy%3fclient_id%3d578134%26redirect_uri%3dhttps%253A%252F%252Fweb.skype.com%26source%3dscomnav%26form%3dmicrosoft_registration%26fl%3dphone2&lc=1033&id=293290&mkt=en-US&psi=skype&lw=1&cobrandid=2befc4b5-19e3-46e8-8347-77317a16a5a5&client_flight=ReservedFlight33%2CReservedFligh&fl=phone2&lic=1&uaid=36ccdf06047b48f9809f803db3b2bf3b" target="_blank" class="fab fa-skype"></a></li>
                </ul>
            </div>
        </div>
        <br />
        <p style="text-align:right">Version:DB(A16-N16-X16-31-0-20)</p>
    </div>
</footer>

<app-demo-sidebar></app-demo-sidebar>

<ngx-scrolltop></ngx-scrolltop>
