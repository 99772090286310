import { Component, OnInit } from '@angular/core';
import { ViewportScroller } from '@angular/common';
import { FormBuilder, FormControl, Validators, FormsModule } from '@angular/forms';
import { GeneralserviceService } from '../../../Services/generalservice.service';
import { Route, Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

@Component({
    selector: 'app-contact',
    templateUrl: './contact.component.html',
    styleUrls: ['./contact.component.scss']
})
export class ContactComponent implements OnInit {
    enquiery: any = [];
    formGroup: any;
    contactForm: FormsModule;
    Fullname: any;
    email: any;
    Message: any;
    subject: any;
    //number: any;
    Phone:any
    loginDet: any; loginDetails: any;
    constructor(private viewportScroller: ViewportScroller, fb: FormBuilder, public generalservice: GeneralserviceService,
        private router: Router) {
        this.loginDet = localStorage.getItem("LoginDetails");
        this.loginDetails = JSON.parse(this.loginDet)
    }

    public onClick(elementId: string): void {
        this.viewportScroller.scrollToAnchor(elementId);
    }

    ngOnInit() {
        this.sendmail();
    }
    reg() {
        if (!this.loginDetails || Object.keys(this.loginDetails).length === 0) {
            // Route to login page
            this.router.navigate(['/Login']);
            return; // Stop further execution
        }
        else {
            this.router.navigate(['/Profile']);
        }
    }

    sendmail() {
        debugger
        this.enquiery = [];
        this.enquiery.push({
            FarmerName: this.Fullname,
            EmailID: this.email,
            Comments: this.Message,
            //Phone: this.Phone

        })
        var uploadfile = new FormData;
        uploadfile.append("Email", JSON.stringify(this.enquiery));
        var url = "api/DressBox/EnquiryMailTo_Dressbox";
        this.generalservice.PostData(url, uploadfile).then(data => {
            debugger
            this.generalservice.ShowAlert("SUCCESS", 'Mail sent Successfuly', 'success');
            Swal.fire('SUCCESS',
                'Your Enquiry has been submitted successfully. Our Team will contact you soon.',
                'success')
            this.Fullname = '';
            this.email = '';
            this.Message = '';

            //setTimeout(() => {
            //    window.location.reload();
            //}, 2000);
        });

    }

}
