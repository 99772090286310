import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import Swal from 'sweetalert2';
import { BehaviorSubject, Observable } from 'rxjs';
import { map } from 'rxjs/operators';
@Injectable({
  providedIn: 'root'
})
export class GeneralserviceService {
    url: any;
    urlname: any;
    loginDet: any;
    prodData: any = []
    public productArr = new BehaviorSubject<any>([]);
    private messageSubject = new BehaviorSubject<any>(null);
    message$ = this.messageSubject.asObservable();

    constructor(public http: HttpClient,) {
        this.loginDet = JSON.parse(localStorage.getItem("LoginDetails"));
    }

    ShowAlert(title, text, type) {
        Swal.fire({
            title: title,
            text: text,
            icon: type,
            confirmButtonText: 'Ok',
            customClass: {
                confirmButton: 'btn-danger'
            }
        })
    }
    ShowAlert1(title, text, type) {
        Swal.fire({
            title: title,
            text: text,
            icon: type,
            confirmButtonText: 'Print',
            //confirmButtonText1: 'Cancel'
        })
    }
    sendMessage(message: any) {
        this.messageSubject.next(message);
    }

    getProducts() {
        return this.productArr.asObservable();
    }

    /*
     Below method is for setting Products from throug services
    */
    setProducts(data: any) {
        this.prodData = []
        this.prodData.push(...data)
        this.productArr.next(this.prodData)
    }
    //Global Method to retrieve data 
    GetData(url) {
        return new Promise((resolve, reject) => {
            this.http.get('../assets/WebService.json').subscribe((data: any) => {

                this.urlname = data.Webservice;
                this.url = this.urlname + url
                this.http.get(this.url).subscribe(data => {

                    resolve(data)
                }, err => {
                    reject(err)
                })
            }, err => {
                reject(err)
            })
        });
    }
    PostData(url, PostParam) {

        return new Promise((resolve, reject) => {

            this.http.get('../assets/WebService.json').subscribe((data: any) => {

                this.urlname = data.Webservice;
                url = data.Webservice + url
                this.http.post(url, PostParam).subscribe(data => {

                    resolve(data)
                }, err => {
                    reject(err)
                })
            }, err => {
                reject(err)
            })
        });
    }
    PostData2(url, PostParam, PostParam2) {

        return new Promise((resolve, reject) => {
            this.http.get('../assets/WebService.json').subscribe((data: any) => {
                this.urlname = data.Webservice;
                url = data.Webservice + url
                this.http.post(url, PostParam, PostParam2).subscribe(data => {
                    resolve(data)
                }, err => {
                    reject(err)
                })
            }, err => {
                reject(err)
            })
        });
    }


    postParam(url, PostParam, PostParam2): Observable<any> {
        return this.http.post(url, PostParam, PostParam2)

    }

    getData(): Observable<any> {
        return this.http.get('../assets/WebService.json')
    }

}
