<!--<div class="demo-modal-panel">
    <button class="sidebar-demo-control" (click)="toggleClass()">
        <span>Links</span>
    </button>
</div>-->


<div class="example-demo-modal" [class.active]="classApplied">
    <div class="inner">
        <ul>
            <li><a href="#" target="_blank" class="fab fa-twitter"></a></li>
            <li><a href="#" target="_blank" class="fab fa-linkedin-in"></a></li>
            <li><a href="#" target="_blank" class="fab fa-instagram"></a></li>
            <li><a href="#" target="_blank" class="fab fa-skype"></a></li>
        </ul>
    </div>
    <!--<div class="header-title">
        <button class="example-demo-modal-control" (click)="toggleClass()">
            <i class="fas fa-times"></i>
        </button>
        <div class="title">View Demo</div>
    </div>-->
</div>
