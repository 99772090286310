
<div id="contact" class="contact-area ptb-100 ">
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <ul>
                <li style="cursor:pointer;"><a routerLink="/home-one"><b style="color:white;">Home / </b></a></li>
                <li class="breadcrumb-item " aria-current="page"><b style="color:white;">Contact Us</b></li>
                <div>Contact Us</div>
            </ul>
        </div>
    </nav>
    <div class="container">
        <div class="section-title">
            <h4>Get in Touch</h4>
            <h2>Let's <span>Contact</span> Us</h2>
            <p>Need Assistance? Let's Chat - Reach Out to Us Today for Support!</p>
        </div>
        <div class="row">
            <div class="col-lg-7 col-md-12">
                <div class="contact-form" style="height: 29rem;">
                    <div class="row">
                        <div class="col-lg-4 col-md-4 col-xs-12">
                            <label style="font-size: 17px;font-weight: 400;">Name</label><br />
                            <input maxlength="50" [(ngModel)]="Fullname" [ngModelOptions]="{standalone: true}" />
                        </div>
                        <div class="col-lg-2"></div>
                        <div class="col-lg-4 col-md-4 col-xs-12">
                            <label style="font-size: 17px;font-weight: 400;">Email</label><br />
                            <input maxlength="50" [(ngModel)]="email" [ngModelOptions]="{standalone: true}" />
                        </div>

                        <div class="col-lg-12 col-md-12 col-xs-12"><br />
                            <label style="margin-left: -2px;font-size: 17px;font-weight: 400;">Message</label>
                            <textarea [(ngModel)]="Message" [ngModelOptions]="{standalone: true}" style="height: 9rem;width: 100%;"></textarea>
                        </div>
                        <div class="col-lg-12 col-md-12 col-xs-12">
                            <button class="btn" (click)="sendmail()" style="border: 1px solid !important;margin-top:1rem;color:#ffffff;margin-left: 1px;background-color: #38768a;">Submit</button>
                        </div>
                    </div>

                </div>
            </div>
            <div class="col-lg-5 col-md-12">
                <div class="contact-info">
                    <div class="d-table">
                        <div class="d-table-cell">
                            <ul>
                                <li><i class="fas fa-map-marker-alt"></i> <span>Location:</span>Vanasthalipuram, Hyderabad<br />500070</li>
                                <li><i class="far fa-envelope"></i> <a href="mailto:hello@Dressbox.com"><span>Email:</span>hello@Dressbox.com</a></li>
                                <li><i class="fas fa-phone-alt"></i> <a href="tel:1244122445515"><span>Call:</span>+91 9876543210</a></li>
                                <li><i class="fas fa-fax"></i> <a href="tel:61724160055"><span>Fax:</span>+617-241-60055</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
