import { Component } from '@angular/core';
import { GeneralserviceService } from '../../Services/generalservice.service';
import { HttpClient, HttpHandler, HttpResponse, HttpRequest } from '@angular/common/http';
import { FormGroup, FormControl, AbstractControl, FormBuilder, Validators } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';
import { NavigateproService } from '../../Services/navigatepro.service'
declare var $
@Component({
  selector: 'app-order-invoice',
  templateUrl: './order-invoice.component.html',
  styleUrls: ['./order-invoice.component.scss']
})
export class OrderInvoiceComponent {

    deliveryCharge: any;
    offerAmount: any;
    grandTotal: any;
    billNo: any;
    orderDate: any;
    orderDetails: any = [];
    orderNo: string;
    LoginDetails: any;
    loginDet: any;
    loginDetails: any;
    HomeUrl: any;
    Sname:any
    TotalPrice: any;
    TransactionStatus: any;
    Id: any; packagedetails: any;
    ProductName: any; Size: any;

    constructor(public generalService: GeneralserviceService, public activeroute: ActivatedRoute,
        public http: HttpClient, public router: Router) {
        debugger
        this.Sname = this.activeroute.snapshot.paramMap.get("OrderInvoice").replace(/\_/g, " ").trim();
        this.Id = localStorage.getItem("Id");
        this.loginDet = localStorage.getItem("LoginDetails");
        this.loginDetails = JSON.parse(this.loginDet)
        this.orderNo = this.activeroute.snapshot.paramMap.get('No')
        this.http.get('../assets/WebService.json').subscribe((data: any) => {
            this.HomeUrl = data.Webservice;
        });
        this.generalService.getProducts().subscribe(res => {
            if (res.length == 0) {//if category id is null
                this.getOrdersbasedOnOrderDetails();
                this.getOrdersbasedOnPackageDetails(); 
            }
            else {

                this.Sname = res[0].OrderID;
                this.getOrdersbasedOnOrderDetails();
                this.getOrdersbasedOnPackageDetails();
            }
        })
        
    }

    ngOnInit(): void {
        this.getOrdersbasedOnPackageDetails();
    }

    getOrdersbasedOnOrderDetails() {
        debugger

        let formData: FormData = new FormData();
        formData.append('Param', this.Sname);
        var url = "/api/DressBox/Get_Admin_Orderdetails_BasedOn_OrderId"
        this.generalService.PostData(url, formData).then((data: any) => {
            if (data && data.length > 0) {
                debugger

                // Assigning the first order's details
                this.Id = data[0].Id
                this.orderDate = data[0].CreatedDate;
                this.orderNo = data[0].OrderID;
                this.billNo = data[0].TransactionID;
                this.TransactionStatus = data[0].TransactionStatus;
                // Calculate totalAmount (sum of all TotalPrice)
                let totalAmount = 0;
                for (let i = 0; i < data.length; i++) {
                    totalAmount += data[i].TotalPrice;
                }

                // Assigning totalAmount
                this.TotalPrice = totalAmount;

                // Assuming the offerAmount and deliveryCharge are also part of the first order's details
                this.offerAmount = data[0].OfferPrice;
                this.deliveryCharge = 0; // You might need to adjust this based on your logic

                // Assigning all order details
                this.orderDetails = data;
            } 
        });
    }




    getOrdersbasedOnPackageDetails() {
        debugger

        let formData: FormData = new FormData();
        formData.append('Param', this.Id);
        var url = "api/DressBox/Get_XmlPackageItems_BasedOn_ProductId"
        this.generalService.PostData(url, formData).then((data: any) => {
            debugger
            this.packagedetails = data;
            this.ProductName = this.packagedetails.ProductName
            this.Size = this.packagedetails.Size

        });
    }
     





    print():
        void {
        debugger
        let printContents, popupWin;
        printContents = document.getElementById('print-section').innerHTML;
        //popupWin = window.open('','_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin = window.open('', '_blank', 'top=0,left=0,height=100%,width=auto');
        popupWin.document.open();
        popupWin.document.write(`
      <html>
        <head>
          <title>Print tab</title>
          <style>
     img{
align-items:center;
    justify-content:center;
    display:inline-block;
    width: 100px;
    border-radius: 50px;
}
table,tr,td{
//border:1px solid gray;
}
          </style>
        </head>
    <body onload="window.print();window.close()">${printContents}</body>
      </html>`
        );
        popupWin.document.close();
    }

}

