import { Component } from '@angular/core';
import { FormGroup, FormBuilder, FormControl, Validators, AbstractControl, } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { Router, ActivatedRoute } from '@angular/router';
import { GeneralserviceService } from '../../Services/generalservice.service';

@Component({
  selector: 'app-forgotpassword',
  templateUrl: './forgotpassword.component.html',
  styleUrls: ['./forgotpassword.component.scss']
})
export class ForgotpasswordComponent {
    form: FormGroup;
    arr: any;
    Mobile: any;
    OTP: any;
    randomnumber: any;
    mobileotp: boolean = false;
    Password: any;
    constructor(public http: HttpClient, private fb: FormBuilder, private router: Router,
        public generalservice: GeneralserviceService) {

    }

    ngOnInit() {
       // this.CheckMobile();
    }

    CheckMobile(Mobile) {
        debugger;
        this.arr = [];
        this.arr.push({
            Mobile: Mobile,

        })
        if (Mobile == undefined || Mobile == "" || Mobile == null) {
            debugger;
            alert("Please Enter Mobile Number")
            // this.openToast();
        }
        else {

            var UploadFile = new FormData();
            UploadFile.append("Mobile", Mobile);
            //UploadFile.append("Flag", "1");
            var url = "api/DressBox/checking_Mobile"
            debugger;
            this.generalservice.PostData(url, UploadFile).then(data => {
                debugger;
                if (data == 'NOTEXIST') {
                    debugger
                    this.OTP = ""
                    this.Mobile = Mobile;
                    //if (data == 'NOTEXIST'){
                    //    this.mobileotp = true;
                    //}
                    this.mobileotp = true;
                    this.SendOtpForMobile();

                }
                else {

                    alert("Please Enter Valid Mobile Number")
                }
            }, err => {
            });
        }

    }

    SendOtpForMobile() {
        debugger

        this.randomnumber = Math.floor(1000 + Math.random() * 9000);
        var msg = "Your Customer OTP is:" + this.randomnumber + " SYCar";
        //var DLTTEID = '1207165215802728246';

        var UploadFile = new FormData();
        UploadFile.append("MobileNo", this.Mobile);
        UploadFile.append("SMS", msg);
        UploadFile.append("OTP", this.randomnumber);
        var url = 'api/DressBox/SendOtpToMobile';
        this.generalservice.PostData(url, UploadFile).then(data => {
            if (data == "SUCCESS") {

            }
            else {

            }
        });
    }

    /*verifyotp for forgotpassword*/
    VerifyOtp(otp) {
        debugger
        if (this.randomnumber == otp) {
          //  $('#myModal3').modal('hide');
          //  $('#myModal4').modal('show');
        }
        else {
            alert("Invalid OTP");
        }
    }
    /*end region*/

    /*Change password*/
    ChangePassword(Password, confirmpassword) {
        if (Password == undefined || Password == null || Password == "" || confirmpassword == undefined || confirmpassword == null || confirmpassword == "") {
            alert("Please enter password");
        }
        else {
            if (Password != confirmpassword) {
                alert("password and confirmpassword must be same");
            }
            else {
                var UploadFile = new FormData();
                UploadFile.append("Password", Password);
                UploadFile.append("Password", this.Password);
                var url = "api/DressBox/Forgot_Password"
                debugger;
                this.generalservice.PostData(url, UploadFile).then(data => {
                    debugger;
                    if (data == 'SUCCESS') {
                        this.generalservice.ShowAlert('SUCESS', 'Change Password Successfully.', 'success')
                      //  $('#myModal4').modal('hide');
                    }
                }, err => {
                   // $('#myModal4').modal('hide');
                    this.generalservice.ShowAlert('ERROR', 'Something went wrong. Please try again later.', 'error')
                });
            }
        }
    }


    Close() {

    }


}
