import { BrowserModule } from '@angular/platform-browser';
import { NgModule, isDevMode } from '@angular/core';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgxScrollTopModule } from 'ngx-scrolltop';
import { CarouselModule } from 'ngx-owl-carousel-o';
import { HttpClientModule } from '@angular/common/http'; // Import HttpClientModule

import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CountUpModule } from 'ngx-countup';
import { NgxTypedJsModule } from 'ngx-typed-js';
// import {TabsModule} from 'ngx-tabset';
import { TooltipModule } from 'ng2-tooltip-directive';
import { WorkWearComponent } from './Dress_box_Pages/work-wear/work-wear.component'

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { PreloaderComponent } from './components/common/preloader/preloader.component';
import { HomeOneComponent } from './components/home-one/home-one.component';
import { NavbarComponent } from './components/common/navbar/navbar.component';
import { WelcomeComponent } from './components/common/welcome/welcome.component';
import { AboutComponent } from './components/common/about/about.component';
import { StrategyComponent } from './components/common/strategy/strategy.component';
import { WhoWeAreComponent } from './components/common/who-we-are/who-we-are.component';
import { CtaComponent } from './components/common/cta/cta.component';
import { SkillComponent } from './components/common/skill/skill.component';
import { FunfactsComponent } from './components/common/funfacts/funfacts.component';
import { TeamComponent } from './components/common/team/team.component';
import { HowWeWorkComponent } from './components/common/how-we-work/how-we-work.component';
import { ServicesComponent } from './components/common/services/services.component';
import { FooterComponent } from './components/common/footer/footer.component';
import { SubscribeComponent } from './components/common/subscribe/subscribe.component';
import { PartnerComponent } from './components/common/partner/partner.component';
import { BlogComponent } from './components/common/blog/blog.component';
import { FeedbackComponent } from './components/common/feedback/feedback.component';
import { FaqComponent } from './components/common/faq/faq.component';
import { PricingComponent } from './components/common/pricing/pricing.component';
import { WorkComponent } from './components/common/work/work.component';
import { WhyWeDifferentComponent } from './components/common/why-we-different/why-we-different.component';
import { ContactComponent } from './components/common/contact/contact.component';
import { HomeTwoComponent } from './components/home-two/home-two.component';
import { HomeThreeComponent } from './components/home-three/home-three.component';
import { HomeFourComponent } from './components/home-four/home-four.component';
import { HomeFiveComponent } from './components/home-five/home-five.component';
import { ServicesTwoComponent } from './components/common/services-two/services-two.component';
import { HomeSixComponent } from './components/home-six/home-six.component';
import { HomeSevenComponent } from './components/home-seven/home-seven.component';
import { HomeEightComponent } from './components/home-eight/home-eight.component';
import { HomeNineComponent } from './components/home-nine/home-nine.component';
import { HomeTenComponent } from './components/home-ten/home-ten.component';
import { HomeElevenComponent } from './components/home-eleven/home-eleven.component';
import { HomeTwelveComponent } from './components/home-twelve/home-twelve.component';
import { HomeThirteenComponent } from './components/home-thirteen/home-thirteen.component';
import { BlogDetailsComponent } from './components/blog-details/blog-details.component';
import { DemoSidebarComponent } from './components/common/demo-sidebar/demo-sidebar.component';
import { ServiceWorkerModule } from '@angular/service-worker';
import { SchoolsComponent } from './Dress_box_Pages/schools/schools.component';
import { SchoolProductsComponent } from './Dress_box_Pages/school-products/school-products.component';
import { LoginComponent } from './Dress_box_Pages/login/login.component'
import { RegistrationComponent } from './Dress_box_Pages/registration/registration.component'
import { AddToCartComponent } from './Dress_box_Pages/add-to-cart/add-to-cart.component'
import { MyOrdersComponent } from './Dress_box_Pages/my-orders/my-orders.component';
import { PackagesComponent } from './Dress_box_Pages/packages/packages.component';
import { ProductdetailsComponent } from './Dress_box_Pages/productdetails/productdetails.component';
import { AddressdetailsComponent } from './Dress_box_Pages/addressdetails/addressdetails.component';
import { PackageDetailsComponent } from './Dress_box_Pages/package-details/package-details.component';
import { OrderInvoiceComponent } from './Dress_box_Pages/order-invoice/order-invoice.component';
import { ForgotpasswordComponent } from './Dress_box_Pages/forgotpassword/forgotpassword.component';
//import { RelatedproductsComponent } from './Dress_box_Pages/relatedproducts/relatedproducts.component'
import { ProfileComponent } from './Dress_box_Pages/profile/profile.component';


@NgModule({
    declarations: [
        AppComponent, ProductdetailsComponent, AddressdetailsComponent, PackageDetailsComponent,// RelatedproductsComponent,
        PreloaderComponent,
        HomeOneComponent,
        NavbarComponent,
        WelcomeComponent,
        AboutComponent,
        StrategyComponent,
        WhoWeAreComponent,
        CtaComponent,
        SkillComponent,
        FunfactsComponent,
        TeamComponent,
        HowWeWorkComponent,
        ServicesComponent,
        FooterComponent,
        SubscribeComponent,
        PartnerComponent,
        BlogComponent,
        FeedbackComponent,
        FaqComponent,
        PricingComponent,
        WorkComponent,
        WhyWeDifferentComponent,
        ContactComponent,
        HomeTwoComponent,
        HomeThreeComponent,
        HomeFourComponent,
        HomeFiveComponent,
        ServicesTwoComponent,
        HomeSixComponent,
        HomeSevenComponent,
        HomeEightComponent,
        HomeNineComponent,
        HomeTenComponent,
        HomeElevenComponent,
        HomeTwelveComponent,
        HomeThirteenComponent,
        BlogDetailsComponent,
        DemoSidebarComponent,
        SchoolsComponent,
        SchoolProductsComponent,
        LoginComponent,
        RegistrationComponent, AddToCartComponent,
        MyOrdersComponent, ForgotpasswordComponent,
        PackagesComponent, WorkWearComponent,
        OrderInvoiceComponent, ProfileComponent
    ],
    imports: [
        BrowserModule, 
        AppRoutingModule, 
        BrowserAnimationsModule,
        NgxScrollTopModule, HttpClientModule,
        CarouselModule,
        FormsModule, ReactiveFormsModule,
        CountUpModule,
        TooltipModule, 
        NgxTypedJsModule,
        ServiceWorkerModule.register('ngsw-worker.js', {
          enabled: !isDevMode(),
          
          registrationStrategy: 'registerWhenStable:30000'
        })
    ],
    providers: [],
    bootstrap: [AppComponent]
})
export class AppModule { }
