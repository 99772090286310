<!--<div class="main-banner item-bg-one">
    <div class="d-table mt-5">

        <div class="d-table-cell">
            <div class="container">
                <div class="row">
                    <div class="col-lg-8">
                        <h1>"Elevate Your School Style with DressBox: Unleash Your Unique Fashion Statement!"</h1>
                        <br /> <small>"Where Quality Uniforms Meet Fashion Trends!"</small><br /><br />
                        <div class="mt-3">
                            <a (click)="onClick('about')" class="btn btn-primary">Schools</a>&nbsp;
                            <a (click)="Packages()" class="btn btn-primary view-work">Packages</a>
                        </div>
                       
                    </div>
                    <div class="col-lg-4">
                        <img src="../../../assets/img/Dressbox/bannerimage.PNG" class="banner-image" />
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>--><br />
<div class="main-banner item-bg-one">
    <div class="d-table">
        <div class="d-table-cell">
            <div class="container">
                <div class="main-banner-text">
                    <div class="container">
                        <div class="row">
                            <div class="col-lg-8">
                                <h1>"Elevate Your School Style with DressBox: Unleash Your Unique Fashion Statement!"</h1>
                                <br /> <small>"Where Quality Uniforms Meet Fashion Trends!"</small><br /><br />
                                <div class="mt-3">
                                    <a routerLink="/Schools" class="btn btn-primary" >Schools</a>&nbsp;
                                    <!--<a (click)="Packages()" class="btn btn-primary view-work"style="    background: #f38100;">Packages</a>-->
                                </div>

                            </div>
                            <div class="col-lg-4">
                                <img src="../../../assets/img/newimages/1.jpeg" class="banner-image"alt="Dressbox" />
                            </div>
                        </div>
                      
                    </div>
                </div>
        </div>
    </div>
</div>
    </div>
<app-welcome></app-welcome>

<!--<app-about></app-about>

<app-strategy></app-strategy>

<app-who-we-are></app-who-we-are>

<app-cta></app-cta>

<app-skill></app-skill>

<app-funfacts></app-funfacts>

<app-team></app-team>

<app-how-we-work></app-how-we-work>-->

<app-services></app-services>

<!--<app-why-we-different></app-why-we-different>

<app-work></app-work>

<app-pricing></app-pricing>

<app-faq></app-faq>

<app-feedback></app-feedback>

<app-blog></app-blog>

<app-partner></app-partner>

<app-subscribe></app-subscribe>-->
