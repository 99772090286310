<div class="topnav"style="padding:6px">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-9">
                <div class="mail_call">
                    <i class="fa fa-fw fa-phone"style="font-size:13px">  </i>
                    <a href="tel:+917799912406" target="_blank" style="color:white;cursor:pointer;font-size:13px">
                        +91 7799912406
                    </a>
                    <a class="mailg" href="mailto:Info@dressbox.com" target="_blank" style="color: white; cursor: pointer; ">
                        <i class="fa fa-envelope"
                           aria-hidden="true"></i> Info@dressbox.com
                    </a>
                </div>
            </div>
            <!--<div class="col-lg-3">
                <div class="mail_call">
                   
                </div>
            </div>-->
         
            <div class="col-lg-6 col-3" style="text-align:end;">
                <div class=" icons ">
                    <a href="https://api.whatsapp.com/send?phone=+917799912406text=&source=&data=" target="_blank" style="color:white;">
                        <i class="fab fa-whatsapp"></i>
                    </a>
                    <a href="https://www.facebook.com/YourPageURL" target="_blank" style="color: white;">
                        <i class="fab fa-facebook-square"></i>
                    </a>
                    <a href="https://twitter.com/YourTwitterHandle" target="_blank" style="color: white;">
                        <i class="fab fa-twitter-square"></i>
                    </a>
                </div>
                <div class="cartt">
                  <span class="nav-link" (click)="cartdedails()"><i class="fas fa-shopping-cart"></i>({{countReturn()}})</span>
                </div>
            </div>
        </div>
    </div>
</div>
<nav
    class="navbar navbar-expand-lg navbar-light bg-light"
    [class.active]="classApplied"
    [ngClass]="{'sticky': isSticky}" >
    <div class="container">
        <a class="navbar-brand" routerLink="/">
            <img src="../../../../assets/img/newimages/bglog.png"class="mobile_logo" />
            <img src="../../../../assets/img/newimages/logo.jpeg" class="dresslogo" alt="Dressbox" />
        </a>
        <div class="loginb">
            <div class="nav-item" *ngIf="!loginDetails">
                <span class="dropdown nav-link">
                    <button class="dropbtn" routerLink="/Login">
                        Login
                    </button>
                </span>
            </div>

            <div class="nav-item" *ngIf="loginDetails">
                <span class="dropdown">
                    <button class="dropbtn">
                        <h2 class="tooltiptext fontt h2" style="color:white;    font-weight: 400;">{{loginDetails[0].Name | slice:0:1}}</h2>
                    </button>
                    <span class="dropdown-content ">
                        <a (click)="Profile()"><i class="fas fa-people-roof"></i> Profile</a>
                        <a (click)="Address()"><i class="fas fa-location"></i> Add address</a>
                        <a routerLink="/MyOrders"><i class="fas fa-list-alt"></i> My Orders </a>
                        <a (click)="logout()"><i class="fas fa-sign-out-alt"></i> Logout</a>
                    </span>
                </span>
            </div>
        </div>
        <div class="navbar-toggler" type="button" (click)="toggleClass()">
            <span class="burger-menu">
                <span class="top-bar"></span>
                <span class="middle-bar"></span>
                <span class="bottom-bar"></span>
            </span>
        </div>
        <div class="collapse navbar-collapse" id="navbarSupportedContent">
            <ul class="navbar-nav ml-auto">
                <li class="nav-item"><span class="nav-link" (click)="home()">Home</span></li>
                <li class="nav-item"><span class="nav-link" (click)="about()">About Us</span></li>
                <li class="nav-item"><span class="nav-link" (click)="Schools()">Schools</span></li>
                <li class="nav-item"><span class="nav-link" (click)="WorkWear()">Work Wear</span></li>
                <li class="nav-item"><span class="nav-link" (click)="contact()">Contact Us</span></li>
                <!--<li class="nav-item"><span class="nav-link" routerLink="/ProductDetails">Product Details</span></li>-->
                <li class="nav-item  "><span class="nav-link" (click)="cartdedails()"><i class="fas fa-shopping-cart"></i>({{countReturn()}})</span></li>
                <li class="nav-item nav-link" *ngIf="!loginDetails">
                    <div class="dropdown">
                        <button class="dropbtn" routerLink="/Login">
                            Login
                        </button>
                    </div>
                </li>
                <li class="nav-item" *ngIf="loginDetails">
                    <div class="dropdown">
                        <button class="dropbtn">

                            <h2 class="tooltiptext fontt h2" style="color:white; font-weight: 400;">{{loginDetails[0].Name | slice:0:1}}</h2>

                        </button>
                        <div class="dropdown-content">

                            <a (click)="Profile()"><i class="fas fa-people-roof"></i> Profile</a>
                            <a (click)="Address()"><i class="fas fa-location"></i> Add address</a>
                            <a routerLink="/MyOrders"><i class="fas fa-list-alt"></i> My Orders </a>
                            <a (click)="logout()"><i class="fas fa-sign-out-alt"></i> Logout</a>
                        </div>
                    </div>
                </li>
            </ul>

            <ul class="navbar-nav ml-auto for-responsive">
                <li class="nav-item"><span class="nav-link" (click)="home()">Home</span></li>
                <li class="nav-item"><span class="nav-link" (click)="about()">About Us</span></li>
                <li class="nav-item"><span class="nav-link" (click)="Schools()">Schools</span></li>
                <li class="nav-item"><span class="nav-link" (click)="WorkWear()">Work Wear</span></li>
                <li class="nav-item"><span class="nav-link" (click)="contact()">Contact Us</span></li>
                <!--<li class="nav-item"><span class="nav-link" routerLink="/ProductDetails">Product Details</span></li>-->
               <!-- <li class="nav-item "><span class="nav-link" routerLink="/AddToCart"> <i class="fas fa-shopping-cart"></i></span>({{countReturn()}})</li>-->
                <!--<li class="nav-item" *ngIf="!loginDetails">
                <span class="dropdown nav-link">
                    <button class="dropbtn" routerLink="/Login">
                        Login
                    </button>
                </span>
            </li>
            <li class="nav-item" *ngIf="loginDetails">
                <span class="dropdown">
                    <button class="dropbtn">
                        <h2 class="tooltiptext fontt h2" style="color:white">{{loginDetails[0].Name | slice:0:1}}</h2>
                    </button>
                    <span class="dropdown-content ">
                        <a (click)="Profile()"><i class="fas fa-people-roof"></i> Profile</a>
                        <a (click)="Address()"><i class="fas fa-location"></i> Add address</a>
                        <a routerLink="/MyOrders"><i class="fas fa-list-alt"></i> My Orders </a>
                        <a (click)="logout()"><i class="fas fa-sign-out-alt"></i> Logout</a>
                    </span>
                </span>
            </li>-->
            </ul>
        </div>
    </div>
</nav>
<style>
    .dresslogo {
        width: 12% !important;
        border-radius: 50% !important;
    }
    .fas {
        color: #f38100;
        font-size: 20px;
        margin-top: 0px !important;
    }
</style>
