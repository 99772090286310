<div id="about" class="about-area ptb-100">

    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <ul>
                <li class="breadcrumb-item" style="cursor:pointer;"><a routerLink="/"><b style="color:white;">Home/</b></a></li>
                <li class="breadcrumb-item bread_active" aria-current="page"><b class="enimy" style="color:white;">Login</b></li>
                <div>Login</div>

            </ul>
        </div>
    </nav>
    <div class="container">
        <div class="row" *ngIf="!UserData">
            <div class="col-lg-6">
                <div class="card">
                    <h4>Welcome to Dressbox</h4>
                    <form [formGroup]="form" (ngSubmit)="OnSubmit()">
                        <input [formControl]="email" class="form-control validation-field" placeholder="Mobile/Admission No" type="text"><br />
                        <div class="form-group">
                            <input [formControl]="Password" class="form-control validation-field" placeholder="Password" type="{{ passwordshow ? 'text' : 'password' }}">
                            <span class="toggle-password" (click)="togglePasswordVisibility()">
                                <i class="fa" [ngClass]="{ 'fa-eye': passwordshow, 'fa-eye-slash': !passwordshow }"></i>
                            </span>
                            <small class="text-danger" *ngIf="form.get('password')?.touched && form.get('password')?.hasError('required')">Password is required</small>
                            <small class="text-danger" *ngIf="form.get('password')?.touched && form.get('password')?.hasError('minlength')">Password should be at least 6 characters long</small>

                        </div><br />
                        <div class="row">

                            <div class="col-lg-10">
                                <div class="row">
                                    <div class="col-lg-1 col-1">
                                        <input type="checkbox" style="width:23px;" [checked]="Remember" (change)="RememberMe($event)" id="login-checkbox">

                                    </div>
                                    <div class="col-lg-8 col-8">
                                        <label for="login-checkbox">Keep&nbsp;me&nbsp;signed&nbsp;in.</label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-2">
                                <!--<div class="forgotpwd">
            <a (click)="ForgotPassword()" class="" style="cursor: pointer;  margin-left: 0px;">Forgot&nbsp;password?</a>
        </div>-->
                            </div>
                        </div>
                       <br />


                        
                        <div class="row">
                            <div class="col-lg-6 col-xs-6">
                                <a href="/Registration">
                                    <div style="padding:10px; cursor:pointer;">
                                        <b> Sign&nbsp;Up</b>
                                    </div>
                                </a>



                            </div>
                            <div class="col-lg-6 col-xs-6">
                                <button type="submit" (click)="OnSubmit()" style="float:right;font-weight:bold">
                                    Login
                                </button>
                            </div>
                        </div>
                    </form>
                </div>

            </div>
            <div class="col-lg-6">
                <div style="align-items:center;display:flex;justify-content:center">
                    <img src="../../../assets/img/newimages/bglog.png" class="bglogo" />
                </div>
            </div>

        </div>
    </div>






    <div *ngIf="UserData && UserData.length >0" class="container">
        <div class="row">
            <div class="col-lg-4 col-xs-7" *ngFor="let users of UserData">
                <div class="card1" (click)="selectCard(users)" [class.selected]="selecteduser === users">
                    <div class="row cards">
                        <div>Admission No: <b>{{users.AdmissionNo}}</b></div>
                        <div>Name: <b>{{users.Name}}</b></div>
                        <div>Class: <b>{{users.ClassName}}</b></div>
                        <div>Syllabus: <b>{{users.SyllabusType}}</b></div>

                    </div>
                </div>
            </div>
        </div>
    </div>




    <!--mobile code-->




    <div class="modal" id="myModal2" style="margin-top:9rem;">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header state modal-primary">
                    <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">Enter Email</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="Close()"><span aria-hidden="true">Ãƒâ€”</span></button>
                </div>
                <div class="modal-body">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <label>Email<span style="color:red">*</span></label>
                        <input [(ngModel)]="Mobile" class="form-control Caps" placeholder="Enter Email" type="text" style="width:450px">
                    </div>
                </div>
                <div class="modal-footer">

                    <button id="closemodal" type="button" class="btn btn-primary" data-dismiss="modal" (click)="Close()">Close</button>
                    <button type="submit" class="btn btn-danger" (click)="CheckMobile(Mobile)">Submit</button>
                </div>
            </div>
        </div>
    </div>
    <!-- #endregion -->
    <!-- #region check otp popup for forgot password-->
    <div class="modal" id="myModal3">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header state modal-primary">
                    <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">Enter Otp</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="Close()"><span aria-hidden="true">Ãƒâ€”</span></button>
                </div>
                <div class="modal-body">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <label>OTP<span style="color:red">*</span></label>
                        <input [(ngModel)]="OTP" class="form-control Caps" placeholder="Enter Otp" type="text" style="width:450px">
                    </div>
                    <div class="row" style="float:right;cursor:pointer;margin-right:10px">
                        <a class="resend" (click)="SendOtpForMobile()">Resend OTP</a>
                    </div>
                </div>
                <div class="modal-footer">

                    <button id="closemodal" type="button" class="btn btn-danger" data-dismiss="modal" (click)="Close()">Close</button>
                    <button type="submit" class="btn btn-primary" (click)="VerifyOtp(OTP)">Submit</button>
                </div>
            </div>
        </div>
    </div>
    <!-- #endregion -->
    <!-- #region update customer and vendor password-->
    <div class="modal" id="myModal4">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header state modal-primary">
                    <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">Change Password</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="Close()"><span aria-hidden="true"></span></button>
                </div>
                <div class="modal-body">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <label>Password<span style="color:red">*</span></label>
                        <input [(ngModel)]="Pwd" class="form-control Caps" placeholder="Enter password" type="{{ passwordshow ? 'text' : 'password' }}" style="width:450px">
                        <span class="toggle-password1" (click)="togglePasswordVisibility()">
                            <i class="fa" [ngClass]="{ 'fa-eye': passwordshow, 'fa-eye-slash': !passwordshow }"></i>
                        </span>
                        <br>
                        <label>Confirm Password<span style="color:red">*</span></label>
                        <input [(ngModel)]="Confirmpassword" class="form-control Caps" placeholder="Enter confirm password" type="{{ passwordshow ? 'text' : 'password' }}" style="width:450px">
                        <span class="toggle-password1" (click)="togglePasswordVisibility()">
                            <i class="fa" [ngClass]="{ 'fa-eye': passwordshow, 'fa-eye-slash': !passwordshow }"></i>
                        </span>
                    </div>


                </div>
                <div class="modal-footer">
                    <button id="closemodal" type="button" class="btn btn-danger" data-dismiss="modal" (click)="Close()">Close</button>
                    <button type="submit" class="btn btn-primary" (click)="ChangePassword(Pwd,Confirmpassword)">Submit</button>

                </div>
            </div>
        </div>
    </div>

    <!--<div class="modal" id="myModal5" style="margin-top:9rem;">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header state modal-primary">
                    <h4 style="text-align:left;" class="modal-title" id="modal-primary-label">Enter Admission No</h4>
                    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="Close()"><span aria-hidden="true">Ãƒâ€”</span></button>
                </div>
                <div class="modal-body">
                    <div class="col-lg-12 col-md-12 col-sm-12 col-xs-12">
                        <label>Admission<span style="color:red">*</span></label>
                        <input [(ngModel)]="Mobile" class="form-control Caps" placeholder="Enter Email" type="text" style="width:450px">
                    </div>
                </div>
                <div class="modal-footer">

                    <button id="closemodal" type="button" class="btn btn-primary" data-dismiss="modal" (click)="Close()">Close</button>
                    <button type="submit" class="btn btn-danger" (click)="CheckMobile(Mobile)">Submit</button>
                </div>
            </div>
        </div>
    </div>-->





