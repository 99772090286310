<div id="about" class="about-area ptb-100">
        <div class="container">
        <div class="row">
            <div class="col-lg-3 mt-5">
                <img src="../../../../assets/img/newimages/logo.jpeg" style="border-radius:50%;"/>
            </div>
            <div class="col-lg-9">

                <div class="title">
                    <p>Registration</p>
                </div>

                <form [formGroup]="RegisterForm" class="list-form">
                    <div class="user_details">
                        <div class="input_box">
                            <label for="name">Full Name</label>
                            <input type="text" id="name" placeholder="Enter your name" formControlName="Name" required>
                            <small class="text-danger" *ngIf="RegisterForm.get('Name').touched">
                                <span *ngIf="RegisterForm.get('Name').hasError('required')">Name is required</span>
                            </small>
                        </div>
                        <div class="input_box">
                            <label for="phone">Phone Number</label>
                            <input type="tel" id="phone" (input)="CheckMobileNo()" formControlName="Mobile" maxlength="10" placeholder="Enter your number" required>
                            <small class="text-danger" *ngIf="RegisterForm.get('Mobile').touched">
                                <span *ngIf="RegisterForm.get('Mobile').hasError('required')">Mobile is required</span>
                                <span *ngIf="checkNo==10">Mobile Number Already Exists</span>
                            </small>
                        </div>
                        <div class="input_box">
                            <label for="email">Email</label>
                            <input type="email" id="email" formControlName="email" placeholder="Enter your email" required>
                        </div>


                        <div class="input_box">
                            <button (click)="SendOTPtoMail()">Send otp</button>
                        </div>
                        <div class="input_box">
                            <label for="pass">OTP</label>
                            <input type="text" id="pass" formControlName="OTP" placeholder="Enter your OTP" required>
                        </div>

                        <div class="input_box">
                            <label for="pass">Password</label>
                            <input type="password" id="pass" formControlName="Password" placeholder="Enter your password" required>
                        </div>

                        <div class="input_box">
                            <label for="pass">Admission No</label>
                            <input type="text" id="pass" formControlName="AdmissionNo" placeholder="Enter your Admission No" required>
                        </div>

                        <div class="input_box">
                            <div class="col-lg-6  xspadding col-6">
                                <label for="pass">Class Name</label>
                                <select id="ClassId" style="cursor:pointer" [(ngModel)]="classname" required>

                                    <option *ngFor="let Classes of classdata" [value]="Classes.ClassName">{{ Classes.ClassName }} </option>
                                </select>
                            </div>
                        </div>

                    </div>


                </form>
                <!--<p-autoComplete formControlName="class" [suggestions]="deptList3" (completeMethod)="searchCont3($event)"
                    field="BLGName" [dropdown]="true" [size]="90"
                    placeholder="Search bloodgroup" [minLength]="1"
                    [style]="{'color': 'black'}"
                    [panelStyle]="{'background-color': 'white','margin-left':'3px','padding':'10px','box-shadow':' rgba(0, 0, 0, 0.24) 0px 3px 8px',}">
    </p-autoComplete>

    <small *ngIf="form.controls.class.touched && form.controls.class.errors?.required"
           class="p-error">class is required.</small>-->


                <div class="reg_btn">
                    <button (click)="insertPhoneNumber(RegisterForm.value)">Register</button>
                </div>
            </div></div>
        </div>
   </div>
              <style>
                  @media only screen and (max-width:600px) {
                      .container {
                          width: 100%;
                          border-radius: 0.5rem;
                          box-shadow: none;
                          overflow: hidden;
                          margin-top: 8%;
                      }
                  }

              </style>
               
