<div id="about" class="about-area ptb-100">
    <nav aria-label="breadcrumb" class="breadcrumb">
        <div class="container">
            <ul>
                <li style="cursor:pointer;"><a><b style="color:white;">Checkout </b></a></li>

            </ul>
        </div>
    </nav>
    <div class="container">
        <div class="container">
            <div class="row">
                <div class="col-lg-10">

                    <span>Delivery address</span>
                    <div class="card" style="padding:5px;margin-top:3%" *ngFor="let item of  addressDetails let i=index">
                        <span>
                            <input type="radio" name="addressRadio" [(ngModel)]="addressId" value="{{item.AID}}" (change)="getSelectedAddress(item.AID)" style="width: 18px;height: 18px;" /> My Address {{i+1}}
                            &nbsp;{{item.Area}}, {{item.HNo}}, {{item.Landmark}},<br />
                            {{item.City}},{{item.State}},{{item.Address}}
                        </span>
                    </div>

                </div>

            </div>
            <div class="row">
                <div class="col-lg-6"></div>
                <div class="col-lg-6">
                    <button class="btn btn-success btn-block" style="    padding: 5px;
    width: 218%;
    background: #2b7689;
    margin-top: 30px;" (click)="PlaceOrder()">
                        Check out <i class="fa fa-angle-right"></i>
                    </button>

                </div>



            </div>
        </div>
    </div>
</div>

